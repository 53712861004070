import routerProvider from "@pankod/refine-react-router-v6";
import React from "react";
import {useGetLocale, useOne, useTranslate} from "@pankod/refine-core";
import {
  Chip,
  DataGrid,
  DeleteButton,
  EditButton, getGridNumericOperators, getGridStringOperators,
  GridColumns,
  ruRU,
  ShowButton,
  Stack,
  useDataGrid
} from "@pankod/refine-mui";
import {IFinanceAccount, IFinanceOperation} from "../../interfaces";
import {List} from "../../components/crud/list";
import {IFinanceOperationRouteParams} from "./index";
import {DateField} from "../../components/fields/date";
import {renderCurrency} from "../../utils/dataRenderUtils";

const { useParams } = routerProvider;

export const FinanceOperationList: React.FC = () => {
  const t = useTranslate();
  const locale = useGetLocale();

  const params: IFinanceOperationRouteParams = useParams();
  const {dataGridProps, tableQueryResult} = useDataGrid<IFinanceOperation>({
    resource: `organizations/${params.org_id ?? 0}/accounts/${params.account_id ?? 0}/operations`,
    initialSorter: [{
      field: "date",
      order: "desc"
    }],
  });

  const {data: account} = useOne<IFinanceAccount>({
    resource: 'accounts',
    id: params.account_id,
    queryOptions: {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  });

  const stringColumnFilterOperations = {
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals',)
  };

  const numberColumnFilterOperations = {
    filterOperators: getGridNumericOperators().filter((operator) => operator.value === '=',)
  };

  const columns = React.useMemo<GridColumns<IFinanceOperation>>(
    () => [
      {
        field: "id",
        headerName: t("entity.id"),
        type: "number",
        width: 50,
        hide: true,
      },
      {
        field: "date",
        headerName: t("financeOperations.date"),
        type: 'dateTime',
        minWidth: 105,
        maxWidth: 185,
        flex: 1,
        hideable: false,
        renderCell: function render({ value }) {
          return <DateField value={value} format="LL" />;
        },
      },
      {
        ...numberColumnFilterOperations,
        field: "amount",
        headerName: t("financeOperations.amount"),
        headerAlign: "left",
        type: 'number',
        minWidth: 150,
        maxWidth: 200,
        flex: 1,
        hideable: false,
        valueGetter: params => renderCurrency(params.row.amount, locale(), account?.data?.currency, params.row.type),
        renderCell: function render({ value, row }) {
          return <Chip variant="outlined" color={row.type !== 'Income' ? "error" : "success"} label={value} />;
        },
      },
      {
        ...stringColumnFilterOperations,
        field: "category",
        headerName: t("financeOperations.category"),
        minWidth: 100,
        flex: 1,
      },
      {
        ...stringColumnFilterOperations,
        field: "payee",
        headerName: t("financeOperations.payee"),
        minWidth: 100,
        flex: 1,
      },
      {
        ...stringColumnFilterOperations,
        field: "description",
        headerName: t("financeOperations.description"),
        minWidth: 150,
        flex: 1,
      },
      {
        field: "tactions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton size="small" hideText recordItemId={row.id} />
              <EditButton size="small" hideText recordItemId={row.id} resourceNameOrRouteName={`organizations/${params.org_id}/accounts/${params.account_id ?? 0}/operations`} />
              <DeleteButton size="small" hideText recordItemId={row.id} resourceNameOrRouteName={`operations`} onSuccess={() => tableQueryResult.refetch()} />
            </Stack>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 120,
        hideable: false,
      },
    ],
    [t, account]
  );

  return (
    <List
      title={t("financeOperations.titles.list", {account: account?.data.name})}
      canCreate={true}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 60}
        rowsPerPageOptions={[10, 25, 50, 100]}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
      />
    </List>
  );
}
